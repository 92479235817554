<template>
  <v-dialog back persistent v-model="dialog" width="70%">
    <v-card>
      <v-card-title class="pl-12"
        >Novo Terminal

        <v-spacer></v-spacer>

        <v-btn color="white" text :to="{ name: 'Terminal' }">
          <v-icon color="#5E6278"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form ref="form">
            <v-stepper v-model="step" class="elevation-0">
              <v-stepper-header class="elevation-0">
                <v-stepper-step :complete="step > 1" step="1">
                  Informações Basicas
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2">
                  Informações Complementares</v-stepper-step
                >

                <v-divider></v-divider>

                <v-stepper-step step="3"> Localizacao</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row>
                    <v-col cols="12">
                      <BaseInputValidator
                        type="textField"
                        label="Nome *"
                        filled
                        rounded
                        dense
                        v-model="form.name"
                        fieldLayout="filled"
                        required
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        filled
                        v-model="form.specialty"
                        :hide-no-data="!search"
                        :items="specialtyItems"
                        :search-input.sync="search"
                        @change="newItem"
                        label="Digite suas especialidades *"
                        multiple
                        rounded
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <span class="subheading">Create</span>
                            <v-chip
                              :color="`${colors[nonce - 1]} lighten-3`"
                              label
                              small
                            >
                              {{ search }}
                            </v-chip>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        label="Descrição"
                        filled
                        rounded
                        dense
                        v-model="form.description"
                        >teste</v-textarea
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    color="primary"
                    x-large
                    @click="step = 2"
                    :disabled="!isValidStepOne"
                  >
                    Continuar
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-row>
                    <v-col cols="10">
                      <div
                        class="rounded-lg v-input v-input--hide-details theme--light v-text-field v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"
                      >
                        <div class="v-input__control">
                          <div
                            class="v-input__slot"
                            style="background-color: #f3f6f9"
                          >
                            <div class="v-text-field__slot">
                              <gmap-autocomplete
                                @place_changed="initMarker"
                                placeholder="Digite o nome do estado, cidade ou bairro."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        color="primary"
                        x-large
                        @click="step = 3"
                        :disabled="!isValidStepMap"
                      >
                        Continuar
                      </v-btn>

                      <v-btn text class="red--text" x-large @click="step = 1">
                        Voltar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-row>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="textField"
                        label="Quantidade de Telas *"
                        v-mask="'###'"
                        filled
                        rounded
                        dense
                        v-model="form.displays"
                        fieldLayout="filled"
                        required
                        :rules="{ required: true }"
                      />
                    </v-col>

                    <v-col cols="6">
                      <BaseInputValidator
                        type="autoComplete"
                        label="Classe Social *"
                        fieldLayout="filled"
                        multiple
                        required
                        rounded
                        dense
                        v-model="form.socialClass"
                        :items="classItems"
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="textField"
                        label="Fluxo Mensal *"
                        placeholder="Ex: 2000  (Pessoas/Mês)"
                        v-mask="'######'"
                        filled
                        rounded
                        dense
                        v-model="form.flow"
                        fieldLayout="filled"
                        required
                        :rules="{ required: true }"
                      />
                    </v-col>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="autoComplete"
                        label="Categorias *"
                        fieldLayout="filled"
                        multiple
                        required
                        rounded
                        dense
                        v-model="form.categories"
                        :items="categoriesItems"
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="textField"
                        label="Horário de Abertura *"
                        placeholder="Ex: 08:00"
                        filled
                        v-mask="'##:##'"
                        rounded
                        dense
                        v-model="form.startHour"
                        fieldLayout="filled"
                        required
                        :rules="{ required: true }"
                      />
                    </v-col>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="textField"
                        label="Horário de Encerramento *"
                        placeholder="Ex: 18:00"
                        filled
                        v-mask="'##:##'"
                        rounded
                        dense
                        v-model="form.endHour"
                        fieldLayout="filled"
                        required
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="autoComplete"
                        label="Dia de Funcionamento"
                        fieldLayout="filled"
                        multiple
                        rounded
                        dense
                        v-model="form.operationDate"
                        :items="operationItems"
                      />
                    </v-col>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="autoComplete"
                        label="Proporção das Telas"
                        fieldLayout="filled"
                        rounded
                        dense
                        v-model="form.proportion"
                        :items="proportionItems"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-file-input
                        v-model="form.fileName"
                        label="Foto "
                        filled
                        rounded
                        dense
                        clearable
                        hide-details
                        @change="handleFile"
                        :prepend-icon="null"
                        append-icon="mdi-cloud-upload-outline"
                        background-color="#F5F8FA"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="6">
                      <BaseInputValidator
                        type="autoComplete"
                        label="Usuário *"
                        filled
                        rounded
                        dense
                        v-model="form.users"
                        fieldLayout="filled"
                        required
                        :items="users"
                        itemValue="_id"
                        itemText="name"
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="auto">
                      <v-btn
                        color="primary"
                        x-large
                        @click="verifyTypeForm"
                        :disabled="invalid"
                      >
                        Enviar
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn text class="red--text" x-large @click="step = 1">
                        Voltar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BaseInputValidator from "../../components/UI/BaseInputValidator";
import terminalService from "./service";
import playlistService from "../playlists/service";
import UsuarioService from "../users/service";
export default {
  name: "CreateTerminal",
  data() {
    return {
      typeForm: "",
      dialog: true,
      valid: true,
      map: null,
      locationMarkers: "",
      center: {},
      step: 1,
      playlists: [],
      categoriesItems: [
        "Hospital",
        "Clínica",
        "Laboratório",
        "Academia",
        "Farmácia",
      ],
      operationItems: [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo",
      ],
      proportionItems: ["4:3", "16:9"],
      groupsItems: ["Group DEMO", "Clínica Sim", "Nova Lapa"],
      classItems: ["AA", "A", "B", "C", "D"],
      specialtyItems: [],
      users: [],
      form: {
        users: "",
        displays: "",
        name: "",
        operationDate: [],
        description: "",
        proportion: [],
        categories: [],
        socialClass: [],
        specialty: [],
        startHour: "",
        endHour: "",
        flow: "",
        file: "",
        location: {
          lat: "",
          lng: "",
        },
      },
      fileName: "",
      isValidStepOne: false,
      isValidStepMap: false,
      activator: null,
      attach: null,
      colors: [""],
      editing: null,
      editingIndex: -1,
      items: [{ header: "Após digitar, aperte [Enter]" }],
      nonce: 1,
      menu: false,
      x: 0,
      search: null,
      y: 0,
    };
  },

  components: {
    ValidationObserver,
    BaseInputValidator,
  },

  watch: {
    form: {
      handler: function (val) {
        if (val.name && val.specialty.length > 0) {
          this.isValidStepOne = true;
        } else {
          this.isValidStepOne = false;
        }
        if (val.location.lat) {
          this.isValidStepMap = true;
        }
      },
      deep: true,
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },

  methods: {
    async sendTerminalInfo() {
      try {
        const terminal = await terminalService.createTerminal(this.form);
        return terminal;
      } catch (error) {
        console.error(error);
      }
    },
    async verifyTypeForm() {
      if (this.typeForm === "EditTerminal") {
        const terminal = await this.updateTerminal();
        this.$router.push({ name: "Terminal" });
        this.$emit("created", { terminal: terminal, typeForm: this.typeForm });
      } else {
        const terminal = await this.sendTerminalInfo();
        this.$router.push({ name: "Terminal" });
        this.$emit("created", { terminal: terminal, typeForm: this.typeForm });
      }
    },
    async updateTerminal() {
      try {
        const terminal = await terminalService.updateTerminal(
          this.form,
          this.$route.params.id
        );
        return terminal;
      } catch (error) {
        console.error(error);
      }
    },
    async initMarker(loc) {
      const city = await loc.address_components.find(
        (item) => item.types[0] === "administrative_area_level_2"
      );
      const state = await loc.address_components.find(
        (item) => item.types[0] === "administrative_area_level_1"
      );
      this.form.location.lat = await loc.geometry.location.lat();
      this.form.location.lng = await loc.geometry.location.lng();
      this.form.location.address = await loc.formatted_address;
      this.form.location.city = city.long_name;
      this.form.location.state = state.short_name;
      console.log(loc);
    },
    async newItem(event) {
      if (!this.specialtyItems.includes(event.at(-1)) && event.length > 0) {
        this.specialtyItems.push(event.at(-1));
      }
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.locationMarkers.push({ position: marker });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    validateStepOne() {
      console.log(this.$refs.observer.validate());
      return true;
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    handleFile(e) {
      this.createBase64File(e);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    async egetPlaylists() {
      try {
        const playlists = await playlistService.getPlaylist();
        this.playlists = playlists.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsers() {
      try {
        const users = await UsuarioService.getUsers();
        this.users = users.data;
      } catch (error) {
        console.error(error);
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },

  async mounted() {
    this.typeForm = this.$route.name;
    if (this.typeForm === "EditTerminal") {
      const terminal = await terminalService.getTerminalById(
        this.$route.params.id,
        { populated: true }
      );
      this.form = terminal.data;
    }
    this.locateGeoLocation();
    await this.getUsers();
  },
};
</script>
