import Api from "@/services";

const createPlaylist = async (object) => {
  const response = await Api.post("v1/playlist", object);
  return response;
};

const getPlaylist = async (query) => {
  try {
    const response = await Api.query("v1/playlist", query);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getPlaylistById = async (id) => {
  try {
    const response = await Api.get("v1/playlist", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const updatePlaylist = async (id, object) => {
  try {
    const response = await Api.put("v1/playlist", id, object);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// const copyPlaylist = async (object, id) => {
//   try {
//     const response = await Api.copy("v1/playlist", id);
//     return response.data;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

const deletePlaylist = async (id) => {
  try {
    const response = await Api.delete("v1/playlist", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getPlaylistsByWeek = async () => {
  try {
    const response = await Api.query("v1/playlist/playlists-by-week");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  createPlaylist,
  getPlaylist,
  getPlaylistById,
  updatePlaylist,
  //   copyPlaylist,
  deletePlaylist,
  getPlaylistsByWeek,
};
