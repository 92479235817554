import Api from "@/services";

const createUser = async (object) => {
  const response = await Api.post("v1/users", object);
  return response;
};

const getUsers = async () => {
  try {
    const response = await Api.query("v1/users");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getUserById = async (id) => {
  try {
    const response = await Api.get("v1/users", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const updateUser = async (object, id) => {
  try {
    const response = await Api.put("v1/users", id, object);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteUser = async (id) => {
  try {
    const response = await Api.delete("v1/users", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default { createUser, getUsers, getUserById, updateUser, deleteUser };
